.inner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* custom.module.css */
.customPageSize {
  max-width: 1140px;
  margin: 0 auto;
  padding: 20px;
}

.customCard {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
  background: white;
  padding: 25px;
}

/* Table Styles */
.table {
  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;
}

.table th {
  background-color: #f8f9fa;
  padding: 12px;
  font-weight: 600;
  font-size: 14px;
}

.table td {
  padding: 12px;
  vertical-align: top;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .customCard {
    padding: 15px;
  }
  
  .table {
    font-size: 13px;
  }
  
  .table th,
  .table td {
    padding: 8px;
  }
}

/* Additional utility classes */
.order_total_button {
  margin-top: 20px;
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order_sub_total {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

