div [data-rsbs-header] {
  display: none;
}

._loading_overlay_wrapper {
  min-height: 100vh;
}

.center_text {
  margin-left: auto !important;
  margin-right: auto !important;
}

/* Responsive tablet code */

@media (max-width: 1024px) and (min-width: 500px) {
  header {
    max-width: 100% !important;
  }

  div.center_text {
    margin-left: 0 !important;
    margin-right: 0 !important;
    text-align: left !important;
  }

  .container.integration_container {
    max-width: 100% !important;
    text-align: left;
  }

  .integration_type {
    margin: 0 0 35px 0 !important;
  }

  .iframe_container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
  }

  iframe {
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  input {
    width: 100% !important;
  }

  button {
    max-width: 331px !important;
  }
}

.business_container {
  /* width: 55%; */
  margin: auto;
  width: 360px;
}

.business_top_container {
  display: flex;
  width: 100%;
}
.business_top_container div {
  flex: 1;
  /* padding-left: 10%; */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.business_card {
  background-color: white;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.business_card a {
  font-family: "Gilroy-Semibold" !important;
  color: #000000;
  font-size: 15px;
  display: flex; /* if using flexbox */
  overflow: hidden;
}

.business_card a p {
  font-family: "Gilroy-Semibold" !important;
  color: #000000;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.business_social_wrapper {
  display: flex;
  margin-top: 20px;
  gap: 1rem;
  align-self: center;
}

.business_social_wrapper a {
  background-color: white;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.business_name {
  font-size: 16px;
  color: black;
  font-family: "Gilroy-SemiBold" !important;
}

.business_designation {
  font-size: 14px;
  color: #000000;
  margin: 0;
  font-family: "Gilroy-Medium" !important;
}

.business_des {
  font-family: "Gilroy-Regular" !important;
  color: #00000080;
  margin-right: auto;
  font-size: 14px;
}

.business_address {
  color: white;
  font-family: "Gilroy-Semibold" !important;
  font-size: 1rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 509px) {
  .iframe_container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 66.66%;
  }

  iframe {
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .business_container {
    width: 90vw !important;
  }
}

iframe {
  pointer-events: auto !important;
  z-index: 10 !important; /* Ensure it is above other elements */
}
