/* orderView.css */
:root {
    --primary-color: #1a202c;
    --secondary-color: #4a5568;
    --border-color: #e2e8f0;
    --background-light: #f7fafc;
    --text-light: #718096;
  }
  
  .quotation-container {
    max-width: 900px;  /* Reduced further */
    margin: 0 auto;
    padding: 12px;
    box-sizing: border-box;
  }
  
  .quotation-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden; /* Add this to contain all content */
  }
  
  /* Header Styles */
  .quotation-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    margin-bottom: 20px;
  }
  
  .company-info {
    display: flex;
    gap: 12px;
    max-width: 60%;
  }
  
  
  .company-logo-name {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .company-logo {
    width: 54px;
    height: 54px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 6px;
    padding: 4px;
    border: 1px solid var(--border-color);
  }
  
  .company-logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .company-details {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  
  .company-name {
    font-size: 18px;
    font-weight: 600;
    color: var(--primary-color);
    margin-bottom: 8px;
  }
  .company-name-duplicate {
    display: none; /* Hide this instance */
  }
  .client-section .section-title {
    font-size: 14px;
    font-weight: 700;  /* Changed from 600 to 700 */
    margin-bottom: 8px;
    color: var(--primary-color);
  }
  .company-address,
.company-contact,
.company-website {
  font-size: 12px;
  color: var(--secondary-color);
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
  line-height: 1.3;
}


.quotation-details-wrapper {
  width: 240px;  /* Reduced */
  flex-shrink: 0;
}

  .company-website {
    font-size: 13px;
    color: #3182ce;
    display: flex;
    align-items: center;
    gap: 6px;
    text-decoration: none;
  }
  
  .company-website:hover {
    text-decoration: underline;
  }
  
  /* quotation Details Styles */
  .quotation-details {
    background-color: var(--background-light);
    padding: 16px;
    border-radius: 8px;
    min-width: 240px;
  }
  
  
  .quotation-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 12px;
    color: var(--primary-color);
    text-align: left;
  }
  
  
  
  .quotation-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }
  
  
  .info-row .label {
    color: var(--secondary-color);
    font-weight: 500;
    white-space: nowrap;
  }
  
  .info-row .value {
    font-weight: 500;
    color: var(--primary-color);
    text-align: right;
  }
  
  
  
  /* Client Section Styles */
  .client-section {
    background-color: var(--background-light);
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  
  .section-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    color: var(--primary-color);
  }
  
  
  .client-details {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  
  .client-info {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    min-width: 200px;
  }  
  
  /* Table Styles */
  .table-container {
    margin-bottom: 16px;
    overflow-x: auto;
  }
  
  .products-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .products-table th,
  .products-table td {
    padding: 8px 12px;
    text-align: left;
    border-bottom: 1px solid #e2e8f0;
    font-size: 13px;
  }
  
  .products-table th {
    background-color: #f7fafc;
    font-weight: 600;
    color: #2d3748;
    font-size: 14px;
  }
  
  .products-table td {
    color: #4a5568;
    font-size: 14px;
  }
  
  .text-right {
    text-align: right;
  }
  
  .product-name {
    font-weight: 500;
    color: #2d3748;
  }
  
  /* Summary Section Styles */
  .summary-section {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;
  }
  
  .calculations {
    width: 100%;
    max-width: 400px;
    background-color: #f7fafc;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 0;  /* Remove bottom margin if any */
  }
  
  .calc-row {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    font-size: 13px;
  }
  
  .total-row {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    margin-top: 16px;
    border-top: 2px solid #e2e8f0;
    font-size: 18px;
    font-weight: 700;
    color: #1a202c;
  }
  
  /* Additional Sections */
  .summary-wrapper {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 16px;
    margin: 16px 0 8px 0;  /* Reduced bottom margin to 8px */
  }
  
  .amount-words h4 {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 8px;
    color: var(--secondary-color);
  }
  
  .words {
    font-size: 13px;
    line-height: 1.4;
    color: var(--primary-color);
  }
  .notes-section {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px dashed var(--border-color);
  }
  .notes-text {
    font-size: 13px;
    color: var(--secondary-color);
  }
  
  
  .additional-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
  }
  
  .terms-section,
  .bank-details {
    background-color: #f7fafc;
    padding: 24px;
    border-radius: 8px;
  }
  
  .bank-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  /* Utility Classes */
  .label {
    color: #4a5568;
    font-weight: 600;
  }

  .value {
    color: #2d3748;
  }
  
  .capitalize {
    text-transform: capitalize;
  }
  
  .icon {
    color: #4a5568;
    flex-shrink: 0;
    width: 14px;
    height: 14px;

  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .quotation-header {
      flex-direction: column;
      gap: 20px;
    }
  
  
    .quotation-header {
      flex-direction: column;
      gap: 16px;
    }
    
      .summary-wrapper {
        grid-template-columns: 1fr;
      }
    }
  
    .company-info {
      display: flex;
      gap: 16px;
      max-width: 60%;
    }
    .company-logo {
      width: 54px;
      height: 54px;
      flex-shrink: 0;
    }
  
    .quotation-details {
      background-color: var(--background-light);
      padding: 16px;
      border-radius: 8px;
      min-width: 240px;
    }
  
    .quotation-title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 12px;
      color: var(--primary-color);
      text-align: left;
    }
    
    .quotation-details-wrapper {
      width: 240px;  /* Reduced */
      flex-shrink: 0;
    }
    .client-details {
      grid-template-columns: 1fr;
    }
  
    /* Calculations Section */
    .calculations {
      background-color: var(--background-light);
      padding: 12px;
      border-radius: 8px;
    }
    .calc-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 0;
      font-size: 13px;
    }
  
  .calc-row .label {
    color: var(--secondary-color);
  }
  
  
  .calc-row .value {
    font-weight: 500;
    text-align: right;
  }
  
  .total-row {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid var(--border-color);
    font-weight: 600;
    font-size: 14px;
  }
    .additional-info {
      grid-template-columns: 1fr;
    }
  
  
  /* Print Styles */
  @media print {
    .quotation-container {
      padding: 0;
      max-width: 100%;
    }
  
    .quotation-card {
      padding: 12px;
      max-height: 29.7cm; /* A4 height */
      margin: 0;
      box-shadow: none;
    }
  
    .products-table th {
      background-color: #f8fafc !important;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  
    .company-logo {
      border: none;
    }
  
    .quotation-details,
    .client-section,
    .calculations,
    .amount-words,
    .terms-section,
    .bank-details {
      background-color: #f8fafc !important;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .quotation-card {
    animation: fadeIn 0.3s ease-out;
  }
  
  /* Status Indicators */
  .status-badge {
    display: inline-flex;
    align-items: center;
    padding: 4px 12px;
    border-radius: 9999px;
    font-size: 12px;
    font-weight: 500;
  }
  
  .status-paid {
    background-color: #def7ec;
    color: #03543f;
  }
  
  .status-pending {
    background-color: #fef3c7;
    color: #92400e;
  }
  
  /* Enhanced Table Styles */
  .products-table tbody tr:hover {
    background-color: #f8fafc;
  }
  
  .products-table th:first-child,
  .products-table td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  
  .products-table th:last-child,
  .products-table td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  /* Section Dividers */
  .section-divider {
    height: 1px;
    background: repeating-linear-gradient(to right, var(--border-color) 0, var(--border-color) 6px, transparent 6px, transparent 12px);
    margin: 12px 0;
    opacity: 0.7;
  }
  
  
  /* Custom Scrollbar */
.table-container::-webkit-scrollbar {
    height: 8px;
  }
  
  .table-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .table-container::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 4px;
  }
  
  .table-container::-webkit-scrollbar-thumb:hover {
    background: #a0aec0;
  }
  /* Footer Layout */
  .quotation-footer {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 24px;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid var(--border-color);
  }
  

/* Card Styles */
.footer-card {
  background-color: var(--background-light);
  padding: 16px;
  border-radius: 8px;
}


/* More compact bank details */
.bank-details .section-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 8px;
  text-align: left;
}

.bank-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.bank-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.bank-label {
  color: var(--secondary-color);
}

.bank-value {
  color: var(--primary-color);
  font-weight: 500;
  text-align: right;
}

/* Terms Content */
.terms-conditions {
  height: 100%;
}

.terms-conditions .section-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 8px;
}

.terms-content {
  font-size: 13px;
  line-height: 1.4;
  color: var(--secondary-color);
}

/* Responsive */
@media (max-width: 768px) {
  .quotation-header {
    flex-direction: column;
  }

  .company-details {
    padding-left: 60px;
  }

  .quotation-details-wrapper {
    width: 240px;  /* Reduced */
    flex-shrink: 0;
  }
}

.quotation-header,
.client-section,
.products-table,
.summary-wrapper,
.footer-section {
  break-inside: avoid;
}
