@font-face {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Bold"), url("../fonts/Gilroy-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "Gilroy-Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Heavy"), url("../fonts/Gilroy-Heavy.ttf") format("ttf");
}

@font-face {
  font-family: "Gilroy-Light";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Light"), url("../fonts/Gilroy-Light.ttf") format("ttf");
}

@font-face {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Medium"), url("../fonts/Gilroy-Medium.ttf") format("ttf");
}

@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Regular"), url("../fonts/Gilroy-Regular.ttf") format("ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.padding {
  padding: 2rem !important;
}

.card {
  margin-bottom: 30px;
  border: none;
  -webkit-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
  -moz-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
  box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
}

.card-header {
  background-color: #fff;
  border-bottom: 1px solid #e6e6f2;
}

h3 {
  font-size: 20px;
}

h5 {
  font-size: 15px;
  line-height: 26px;
  color: #3d405c;
  margin: 0px 0px 15px 0px;
  font-family: "Circular Std Medium";
}

.mb-5 {
  margin-bottom: 5rem;
}

.h3_font {
  font-size: 22px !important;
  font-family: Gilroy-Bold;
}

.h4_font {
  font-size: 16px !important;
  margin-bottom: 5px !important;
  font-weight: 700;
}

.text-dark {
  color: rgb(19, 39, 52) !important;
}

.font_label_to {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.font_label {
  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
}

.for_label {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.font_label label {
  font-weight: 300;
  margin-left: 4px;
}

table {
  margin-bottom: 0rem !important;
}

table thead tr {
  background-color: #f0f3f8;
  color: #0e243a !important;
  font-weight: 900;
  font-size: 18px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffff;
}

table tbody tr td {
  font-weight: 800;
  color: #0e243a !important;
  padding: 15px !important;
  border: none !important;
}
table tbody .customColor td {
  color: white !important;
  background: rebeccapurple;
  padding: 500px;
}

table thead tr th {
  border: none !important;
  padding: 15px !important;
}

.tdFooter {
  border: none !important;
}

table {
  border: none !important;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.bdr_none {
  border: none !important;
}

.bdr_lr {
  width: 130px;
  border-right: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.tbl_total {
  background-color: #f5f5f5;
  font-size: 20px;
  font-weight: 700;
}

.font_20 {
  font-size: 20px;
}

.GilroyBold {
  font-family: Gilroy-Bold;
}

.customOrder {
  color: rgb(19, 39, 52) !important;
}

.GilroyHeavy {
  font-family: Gilroy-Heavy;
}

.GilroyLight {
  font-family: Gilroy-Light;
}

.GilroyMedium {
  font-family: Gilroy-Medium;
}

.GilroyRegular {
  font-family: Gilroy-Regular;
}

.savePdfBtn {
  background-color: #f5f5f5;
  border: 1px solid;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.savePdfBtn:hover {
  cursor: pointer;
}

.image-container{
  height: 500px; /* Set the height of the container */
  width: 100%;   /* Set the width of the container to 100% */
  background-size: contain; /* Scale the background image to fit the container without stretching */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  background-position: center center; /* Center the background image */
}
.inner-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
